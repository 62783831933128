<template>
  <div>
    <div class="text-right mb-5">
      <v-btn small class="mr-2" color="success" @click="showDialogAccount">
        {{ $t("labels.zalo_oa_manage") }}
      </v-btn>
      <v-btn small color="primary" @click="showDialogTemplate">
        {{ $t("labels.template") }}
      </v-btn>
    </div>

    <v-simple-table class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center" colspan="10">
              <div class="text-h6 primary--text">
                {{ $t("labels.zalo_template_action_type_1") }}
              </div>
            </th>
          </tr>
          <tr>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.order_type')"
                :placeholder="$t('labels.order_type')"
                name="order_type"
                sort-name="order_type"
                has-sort
                :sorting="createFilters.sort_by"
                :options="createOrderTypes"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.classify')"
                :placeholder="$t('labels.classify')"
                name="classify"
                sort-name="classify"
                has-sort
                :sorting="createFilters.sort_by"
                :options="classifyOptions(createFilters)"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center c-fb0000">
              {{ $t("labels.code") }}
            </th>
            <th role="columnheader" class="text-center c-fb0000">
              {{ $t("labels.order_source") }}
            </th>
            <th role="columnheader" class="text-center c-fb0000">
              {{ $t("labels.shop_channel") }}
            </th>
            <th role="columnheader" class="text-center c-fb0000">
              {{ $t("labels.warehouse_status") }}
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.zalo_oa')"
                :placeholder="$t('labels.zalo_oa')"
                name="id_customer_zalo"
                sort-name="id_customer_zalo"
                has-sort
                :sorting="createFilters.sort_by"
                :options="accountOptions"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.sent')"
                :placeholder="$t('labels.sent')"
                name="id_customer_zalo_zns_template"
                sort-name="id_customer_zalo_zns_template"
                has-sort
                :sorting="createFilters.sort_by"
                :options="templateOptions(createFilters)"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.activity')"
                :placeholder="$t('labels.activity')"
                name="status"
                sort-name="status"
                has-sort
                :sorting="createFilters.sort_by"
                :options="yesNoOptions"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center" style="width: 100px">
              <v-btn
                small
                color="primary"
                @click="addCreateItem"
                :disabled="isDisabledBtnAddCreate"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-for="(item, index) in createItems"
            :key="`c_${index}`"
          >
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.order_type"
                :items="createOrderTypes"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.classify"
                :items="classifyOptions(item)"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <select-multiple-model
                v-if="item.classify === 2"
                v-model="item.id_poss"
                :options="posOptions"
                :disabled="!item.editing"
              ></select-multiple-model>

              <select-multiple-model
                v-else
                v-model="item.id_warehouses"
                :options="warehouseOptions"
                :disabled="!item.editing"
              ></select-multiple-model>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.source_type"
                :items="sourceOptions(item)"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <select-multiple-model
                v-if="item.source_type === 0"
                v-model="item.id_sources"
                :options="ortherSourceOptions"
                :disabled="!item.editing"
              ></select-multiple-model>

              <select-multiple-model
                v-else
                v-model="item.id_shops"
                :options="shopOptions(item)"
                :disabled="!item.editing"
              ></select-multiple-model>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.state"
                :items="statusCreateOptions"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.id_customer_zalo"
                :items="accountOptions"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.id_customer_zalo_zns_template"
                :items="templateOptions(item)"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center">
              <template v-if="!item.editing">
                <v-btn
                  class="mb-1"
                  x-small
                  color="warning"
                  @click="toggleUpdateEditing(item, index, true)"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
                <v-btn
                  x-small
                  color="purple"
                  outlined
                  @click="showDialogBlackList(item)"
                >
                  {{ $t("labels.zalo_black_list") }}
                </v-btn>
              </template>

              <template v-else>
                <v-btn
                  class="mr-1"
                  x-small
                  color="success"
                  @click="saveItem(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <v-btn
                  x-small
                  color="error"
                  @click="toggleUpdateEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>

        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center" colspan="10">
              <div class="text-h6 primary--text mt-8">
                {{ $t("labels.zalo_template_action_type_2") }}
              </div>
            </th>
          </tr>
          <tr>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.order_type')"
                :placeholder="$t('labels.order_type')"
                name="order_type"
                sort-name="order_type"
                has-sort
                :sorting="updateFilters.sort_by"
                :options="updateOrderTypes"
                @onFilter="onUpdateFilterChange"
                @onSort="onUpdateSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.classify')"
                :placeholder="$t('labels.classify')"
                name="classify"
                sort-name="classify"
                has-sort
                :sorting="updateFilters.sort_by"
                :options="classifyOptions(updateFilters)"
                @onFilter="onUpdateFilterChange"
                @onSort="onUpdateSortChange"
              />
            </th>
            <th role="columnheader" class="text-center c-fb0000">
              {{ $t("labels.code") }}
            </th>
            <th role="columnheader" class="text-center c-fb0000">
              {{ $t("labels.order_source") }}
            </th>
            <th role="columnheader" class="text-center c-fb0000">
              {{ $t("labels.shop_channel") }}
            </th>
            <th role="columnheader" class="text-center c-fb0000">
              {{ $t("labels.warehouse_status") }}
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.zalo_oa')"
                :placeholder="$t('labels.zalo_oa')"
                name="id_customer_zalo"
                sort-name="id_customer_zalo"
                has-sort
                :sorting="updateFilters.sort_by"
                :options="accountOptions"
                @onFilter="onUpdateFilterChange"
                @onSort="onUpdateSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.sent')"
                :placeholder="$t('labels.sent')"
                name="id_customer_zalo_zns_template"
                sort-name="id_customer_zalo_zns_template"
                has-sort
                :sorting="updateFilters.sort_by"
                :options="templateOptions(updateFilters)"
                @onFilter="onUpdateFilterChange"
                @onSort="onUpdateSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.activity')"
                :placeholder="$t('labels.activity')"
                name="status"
                sort-name="status"
                has-sort
                :sorting="updateFilters.sort_by"
                :options="yesNoOptions"
                @onFilter="onUpdateFilterChange"
                @onSort="onUpdateSortChange"
              />
            </th>
            <th role="columnheader" class="text-center" style="width: 100px">
              <v-btn
                small
                color="primary"
                @click="addUpdateItem"
                :disabled="isDisabledBtnAddUpdate"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-for="(item, index) in updateItems"
            :key="`u_${index}`"
          >
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.order_type"
                :items="updateOrderTypes"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.classify"
                :items="classifyOptions(item)"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <select-multiple-model
                v-if="item.classify === 2"
                v-model="item.id_poss"
                :options="posOptions"
                :disabled="!item.editing"
              ></select-multiple-model>

              <select-multiple-model
                v-else
                v-model="item.id_warehouses"
                :options="warehouseOptions"
                :disabled="!item.editing"
              ></select-multiple-model>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.source_type"
                :items="sourceOptions(item)"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <select-multiple-model
                v-if="item.source_type === 0"
                v-model="item.id_sources"
                :options="ortherSourceOptions"
                :disabled="!item.editing"
              ></select-multiple-model>

              <select-multiple-model
                v-else
                v-model="item.id_shops"
                :options="shopOptions(item)"
                :disabled="!item.editing"
              ></select-multiple-model>
            </td>
            <td>
              <select-multiple-model
                v-model="item.statuses"
                :options="statusOptions"
                :disabled="!item.editing"
              ></select-multiple-model>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.id_customer_zalo"
                :items="accountOptions"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.id_customer_zalo_zns_template"
                :items="templateOptions(item)"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center">
              <template v-if="!item.editing">
                <v-btn
                  class="mb-1"
                  x-small
                  color="warning"
                  @click="toggleUpdateEditing(item, index, true)"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
                <v-btn
                  x-small
                  color="purple"
                  outlined
                  @click="showDialogBlackList(item)"
                >
                  {{ $t("labels.zalo_black_list") }}
                </v-btn>
              </template>

              <template v-else>
                <v-btn
                  class="mr-1"
                  x-small
                  color="success"
                  @click="saveItem(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <v-btn
                  x-small
                  color="error"
                  @click="toggleUpdateEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>

        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center" colspan="10">
              <div class="text-h6 primary--text mt-8">
                {{ $t("labels.zalo_template_action_type_3") }}
              </div>
            </th>
          </tr>
          <tr>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.order_type')"
                :placeholder="$t('labels.order_type')"
                name="order_type"
                sort-name="order_type"
                has-sort
                :sorting="deliveryFilters.sort_by"
                :options="updateOrderTypes"
                @onFilter="onUpdateFilterChange"
                @onSort="onUpdateSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.classify')"
                :placeholder="$t('labels.classify')"
                name="classify"
                sort-name="classify"
                has-sort
                :sorting="deliveryFilters.sort_by"
                :options="classifyOptions(deliveryFilters)"
                @onFilter="onUpdateFilterChange"
                @onSort="onUpdateSortChange"
              />
            </th>
            <th role="columnheader" class="text-center c-fb0000">
              {{ $t("labels.code") }}
            </th>
            <th role="columnheader" class="text-center c-fb0000">
              {{ $t("labels.order_source") }}
            </th>
            <th role="columnheader" class="text-center c-fb0000">
              {{ $t("labels.shop_channel") }}
            </th>
            <th role="columnheader" class="text-center c-fb0000">
              {{ $t("labels.summary_status") }}
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.zalo_oa')"
                :placeholder="$t('labels.zalo_oa')"
                name="id_customer_zalo"
                sort-name="id_customer_zalo"
                has-sort
                :sorting="deliveryFilters.sort_by"
                :options="accountOptions"
                @onFilter="onUpdateFilterChange"
                @onSort="onUpdateSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.sent')"
                :placeholder="$t('labels.sent')"
                name="id_customer_zalo_zns_template"
                sort-name="id_customer_zalo_zns_template"
                has-sort
                :sorting="deliveryFilters.sort_by"
                :options="templateOptions(deliveryFilters)"
                @onFilter="onUpdateFilterChange"
                @onSort="onUpdateSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :label="$t('labels.activity')"
                :placeholder="$t('labels.activity')"
                name="status"
                sort-name="status"
                has-sort
                :sorting="deliveryFilters.sort_by"
                :options="yesNoOptions"
                @onFilter="onUpdateFilterChange"
                @onSort="onUpdateSortChange"
              />
            </th>
            <th role="columnheader" class="text-center" style="width: 100px">
              <v-btn
                small
                color="primary"
                @click="addDeliveryItem"
                :disabled="isDisabledBtnAddDelivery"
              >
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="text-center"
            v-for="(item, index) in deliveryItems"
            :key="`d_${index}`"
          >
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.order_type"
                :items="updateOrderTypes"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.classify"
                :items="classifyOptions(item)"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <select-multiple-model
                v-if="item.classify === 2"
                v-model="item.id_poss"
                :options="posOptions"
                :disabled="!item.editing"
              ></select-multiple-model>

              <select-multiple-model
                v-else
                v-model="item.id_warehouses"
                :options="warehouseOptions"
                :disabled="!item.editing"
              ></select-multiple-model>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.source_type"
                :items="sourceOptions(item)"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <select-multiple-model
                v-if="item.source_type === 0"
                v-model="item.id_sources"
                :options="ortherSourceOptions"
                :disabled="!item.editing"
              ></select-multiple-model>

              <select-multiple-model
                v-else
                v-model="item.id_shops"
                :options="shopOptions(item)"
                :disabled="!item.editing"
              ></select-multiple-model>
            </td>
            <td>
              <select-multiple-model
                v-model="item.statuses"
                :options="deliveryStatusOptions"
                :disabled="!item.editing"
              ></select-multiple-model>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.id_customer_zalo"
                :items="accountOptions"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="c-input-small"
                v-model="item.id_customer_zalo_zns_template"
                :items="templateOptions(item)"
                :disabled="!item.editing"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td>
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.status"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center">
              <template v-if="!item.editing">
                <v-btn
                  class="mb-1"
                  x-small
                  color="warning"
                  @click="toggleDeliveryEditing(item, index, true)"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
                <v-btn
                  x-small
                  color="purple"
                  outlined
                  @click="showDialogBlackList(item)"
                >
                  {{ $t("labels.zalo_black_list") }}
                </v-btn>
              </template>

              <template v-else>
                <v-btn
                  class="mr-1"
                  x-small
                  color="success"
                  @click="saveItem(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <v-btn
                  x-small
                  color="error"
                  @click="toggleDeliveryEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="templateDialog" persistent max-width="960px">
      <ZaloZnsTemplate v-if="templateDialog" @cancel="hideDialogTemplate" />
    </v-dialog>

    <v-dialog v-model="accountDialog" persistent max-width="640px">
      <ZaloOA v-if="accountDialog" @cancel="hideDialogAccount" />
    </v-dialog>

    <v-dialog v-model="blacklistDialog" persistent max-width="640px">
      <ZaloBlackList
        v-if="blacklistDialog"
        :item="updatingItem"
        @cancel="hideDialogBlackList"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  YES_NO_OPTIONS,
  GOODS_ISSUE_STATE,
  DELIVERY_STATE,
} from "@/libs/const";
import { httpClient } from "@/libs/http";

export default {
  name: "ZaloZnsConfig",
  components: {
    ZaloZnsTemplate: () => import("@/components/account/ZaloZnsTemplate"),
    ZaloOA: () => import("@/components/account/ZaloOA"),
    ZaloBlackList: () => import("@/components/account/ZaloBlackList"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
  },
  data: () => ({
    createFilters: {
      action_type: 1,
    },
    updateFilters: {
      action_type: 2,
    },
    deliveryFilters: {
      action_type: 3,
    },
    isLoading: false,
    accountDialog: false,
    templateDialog: false,
    blacklistDialog: false,
    updatingItem: {},
    createItems: [],
    updateItems: [],
    deliveryItems: [],
    sources: [],
    shops: [],
    accounts: [],
    templates: [],
    yesNoOptions: [...YES_NO_OPTIONS],
    statusOptions: [...GOODS_ISSUE_STATE],
    deliveryStatusOptions: [...DELIVERY_STATE],
    statusCreateOptions: [
      {
        text: "Tạo đơn",
        value: null,
      },
    ],
    warehouses: [],
    poss: [],
    createOrderTypes: [
      {
        text: "TMĐT",
        value: 1,
      },
      {
        text: "Bán trực tiếp",
        value: 2,
      },
    ],
    updateOrderTypes: [
      {
        text: "TMĐT",
        value: 1,
      },
    ],
  }),
  computed: {
    isDisabledBtnAddCreate() {
      const checkItem = [...this.createItems].find((item) => !item.id);
      return !!checkItem;
    },
    isDisabledBtnAddUpdate() {
      const checkItem = [...this.updateItems].find((item) => !item.id);
      return !!checkItem;
    },
    isDisabledBtnAddDelivery() {
      const checkItem = [...this.deliveryItems].find((item) => !item.id);
      return !!checkItem;
    },
    classifyOptions() {
      return (item) =>
        item.order_type === 2
          ? [
              {
                text: "Pos",
                value: 2,
              },
            ]
          : [
              {
                text: "Kho",
                value: 1,
              },
              {
                text: "Pos",
                value: 2,
              },
            ];
    },
    sourceOptions() {
      return (item) =>
        item.order_type === 2
          ? [
              {
                text: "Bán hàng",
                value: null,
              },
            ]
          : [
              {
                text: "Tất cả",
                value: null,
              },
              {
                text: "Shopee",
                value: 1,
              },
              {
                text: "Lazada",
                value: 2,
              },
              {
                text: "Tiktok Shop",
                value: 5,
              },
              {
                text: "Khác",
                value: 0,
              },
            ];
    },
    shopOptions() {
      return (item) =>
        (item.source_type &&
          [...this.shops]
            .filter(
              (shop) =>
                item.source_type === shop.id_e_marketplace &&
                (item.id_poss.includes(shop.id_pos) ||
                  item.id_warehouses.includes(shop.id_warehouse))
            )
            .map((shop) => ({
              text: shop.display_name,
              value: shop.id,
            }))) ||
        [];
    },
    ortherSourceOptions() {
      return (
        [...this.sources].map((source) => ({
          text: `${this.$t(`labels.source_type_${source.type}`)}: ${
            source.name
          }`,
          value: source.id,
        })) || []
      );
    },
    accountOptions() {
      return [...this.accounts].map((acc) => ({
        text: acc.name,
        value: acc.id,
      }));
    },
    templateOptions() {
      return (item) =>
        [...this.templates]
          .filter(
            (temp) =>
              temp.id_customer_zalo === item.id_customer_zalo &&
              temp.action_type === item.action_type
          )
          .map((temp) => ({
            text: temp.name,
            value: temp.id,
          }));
    },
    posOptions() {
      return [...this.poss].map((pos) => ({
        text: pos.code,
        value: pos.id,
      }));
    },
    warehouseOptions() {
      return [...this.warehouses].map((warehouse) => ({
        text: warehouse.code_name,
        value: warehouse.id,
      }));
    },
  },
  watch: {
    createFilters: {
      handler() {
        this.page = 1;
        this.getCreateList();
      },
      deep: true,
    },
    updateFilters: {
      handler() {
        this.page = 1;
        this.getUpdateList();
      },
      deep: true,
    },
    deliveryFilters: {
      handler() {
        this.page = 1;
        this.getDeliveryList();
      },
      deep: true,
    },
  },
  mounted() {
    this.getWarehouseAndPos();
    this.getCreateList();
    this.getUpdateList();
    this.getDeliveryList();
  },
  methods: {
    onFilterChange(filter) {
      this.createFilters = {
        ...this.createFilters,
        [filter.name]: filter.value,
      };
    },
    onSortChange(sort) {
      this.createFilters = { ...this.createFilters, ...sort };
    },
    onUpdateFilterChange(filter) {
      this.updateFilters = {
        ...this.updateFilters,
        [filter.name]: filter.value,
      };
    },
    onUpdateSortChange(sort) {
      this.updateFilters = { ...this.updateFilters, ...sort };
    },
    onDeliveryFilterChange(filter) {
      this.deliveryFilters = {
        ...this.deliveryFilters,
        [filter.name]: filter.value,
      };
    },
    onDeliverySortChange(sort) {
      this.deliveryFilters = { ...this.deliveryFilters, ...sort };
    },
    showDialogTemplate() {
      this.templateDialog = true;
    },
    hideDialogTemplate() {
      this.templateDialog = false;
      this.getCreateList();
      this.getUpdateList();
    },
    showDialogAccount() {
      this.accountDialog = true;
    },
    hideDialogAccount() {
      this.accountDialog = false;
      this.getCreateList();
      this.getUpdateList();
    },
    showDialogBlackList(item) {
      this.updatingItem = { ...item };
      this.blacklistDialog = true;
    },
    hideDialogBlackList() {
      this.updatingItem = {};
      this.blacklistDialog = false;
    },
    addCreateItem() {
      const item = {
        editing: true,
        id: null,
        action_type: 1,
        order_type: null,
        classify: null,
        source_type: null,
        id_customer_zalo: null,
        id_customer_zalo_zns_template: null,
        state: null,
        id_warehouses: [],
        id_poss: [],
        id_shops: [],
        id_sources: [],
        status: 1,
      };
      this.createItems.unshift(item);
    },
    addUpdateItem() {
      const item = {
        editing: true,
        action_type: 2,
        id: null,
        order_type: 1,
        classify: null,
        source_type: null,
        id_customer_zalo: null,
        id_customer_zalo_zns_template: null,
        id_warehouses: [],
        id_poss: [],
        id_shops: [],
        id_sources: [],
        statuses: [],
        delivery_statuses: [],
        status: 1,
      };
      this.updateItems.unshift(item);
    },
    addDeliveryItem() {
      const item = {
        editing: true,
        action_type: 3,
        id: null,
        order_type: 1,
        classify: null,
        source_type: null,
        id_customer_zalo: null,
        id_customer_zalo_zns_template: null,
        id_warehouses: [],
        id_poss: [],
        id_shops: [],
        id_sources: [],
        statuses: [],
        delivery_statuses: [],
        status: 1,
      };
      this.deliveryItems.unshift(item);
    },
    toggleCreateEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.createItems = [...this.createItems].filter(
          (i, idx) => idx !== index
        );
      } else {
        this.createItems[index] = { ...item };
      }
      this.createItems = [...this.createItems];
    },
    toggleUpdateEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.updateItems = [...this.updateItems].filter(
          (i, idx) => idx !== index
        );
      } else {
        this.updateItems[index] = { ...item };
      }
      this.updateItems = [...this.updateItems];
    },
    toggleDeliveryEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.deliveryItems = [...this.deliveryItems].filter(
          (i, idx) => idx !== index
        );
      } else {
        this.deliveryItems[index] = { ...item };
      }
      this.deliveryItems = [...this.deliveryItems];
    },
    getWarehouseAndPos() {
      const { warehouses, poss } = window.me;
      this.warehouses = [...warehouses];
      this.poss = [...poss];
    },
    async saveItem(item) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/save-zalo-zns-config", item);
        this.isLoading = false;
        const msg = item.id
          ? this.$t("messages.update_success")
          : this.$t("messages.create_success");
        this.$vToastify.success(msg);
        this.getCreateList();
        this.getUpdateList();
        this.getDeliveryList();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async getCreateList() {
      try {
        const { data } = await httpClient.post("/get-zalo-zns-configs", {
          ...this.createFilters,
        });
        this.createItems = [...data.configs].map((item) => ({
          ...item,
          state: null,
          id_warehouses: item.id_warehouses || [],
          id_poss: item.id_poss || [],
          id_shops: item.id_shops || [],
          id_sources: item.id_sources || [],
          statuses: item.statuses || [],
          delivery_statuses: item.delivery_statuses || [],
          action_type: 1,
          editing: false,
        }));
        this.sources = [...data.sources];
        this.shops = [...data.shops];
        this.accounts = [...data.accounts];
        this.templates = [...data.templates];
      } catch (e) {
        console.log(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async getUpdateList() {
      try {
        const { data } = await httpClient.post("/get-zalo-zns-configs", {
          ...this.updateFilters,
        });
        this.updateItems = [...data.configs].map((item) => ({
          ...item,
          action_type: 2,
          id_warehouses: item.id_warehouses || [],
          id_poss: item.id_poss || [],
          id_shops: item.id_shops || [],
          id_sources: item.id_sources || [],
          statuses: item.statuses || [],
          delivery_statuses: item.delivery_statuses || [],
          editing: false,
        }));
      } catch (e) {
        console.log(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async getDeliveryList() {
      try {
        const { data } = await httpClient.post("/get-zalo-zns-configs", {
          ...this.deliveryFilters,
        });
        this.deliveryItems = [...data.configs].map((item) => ({
          ...item,
          action_type: 3,
          id_warehouses: item.id_warehouses || [],
          id_poss: item.id_poss || [],
          id_shops: item.id_shops || [],
          id_sources: item.id_sources || [],
          statuses: item.statuses || [],
          delivery_statuses: item.delivery_statuses || [],
          editing: false,
        }));
      } catch (e) {
        console.log(e);
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
};
</script>

<style scoped></style>
